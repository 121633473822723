
.boat_gallery {
    margin-bottom: 100px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
    .col_12 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}
@media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col_6  {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;

    flex: 0 0 50%;
    max-width: 50%;
}


@media (min-width: 768px) {
  .col_6 {  /* col-lg-3 */
    flex: 0 0 50%;
    max-width: 50%;
  }
}


.col_12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  
    flex: 0 0 100%;
    max-width: 100%;
    flex: 0 0 25%;
    max-width: 25%;
  }

.col_image {
    margin-bottom: 30px;

}

.col_image .image_wrapper {
    border-radius: 0.25rem;
    height: 300px;
    background-position: center center;
    background-size: cover;
    transition: box-shadow 0.3s;
    position: relative;
}

.boat_gallery .col_image .image_wrapper .zoom_animation,
.boat_gallery .col_image .image_wrapper .image_overlay {
    display: none;
}

@media (min-width: 992px) {
    .boat_gallery .col_image .image_wrapper:hover,
    .boat_gallery .col_image .image_wrapper:focus,
    .boat_gallery .col_image .image_wrapper:active {
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.35);
    }
    .boat_gallery .col_image .image_wrapper:hover .image_overlay,
    .boat_gallery .col_image .image_wrapper:focus .image_overlay,
    .boat_gallery .col_image .image_wrapper:active .image_overlay {
      opacity: 1;
      visibility: visible;
    }
    .boat_gallery .col_image .image_wrapper:hover .zoom_animation,
    .boat_gallery .col_image .image_wrapper:focus .zoom_animation,
    .boat_gallery .col_image .image_wrapper:active .zoom_animation {
      opacity: 1;
      visibility: visible;
    }
    .boat_gallery .col_image .image_wrapper .zoom_animation,
    .boat_gallery .col_image .image_wrapper .image_overlay {
      display: block;
    }
    .boat_gallery .col_image .image_wrapper .image_overlay {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }
    .boat_gallery .col_image .image_wrapper .zoom_animation {
      opacity: 0;
      visibility: hidden;
      z-index: 10;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      transition: opacity 0.4s, visibility 0.4s;
    }
    .boat_gallery .col_image .image_wrapper .zoom_animation svg {
      width: inherit;
      height: inherit;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .boat_gallery .col_image .image_wrapper .zoom_animation svg * {
      fill: #343a40;
    }
}


.col_12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  /* margin: auto; */
}

.boat_gallery h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

/* hero image */
.hero_div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

.hero_div picture {
  display: block;
  height: auto;
  max-width: 100%;
  box-sizing: border-box;
  margin: 15px;
}

.o_pic img {
    width: 100%;
    height: auto;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
  border: none;
}