/* @import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap'); */


.footer {   
    border-top: 30px;
    margin: auto;
    padding-top: 2rem;
    position: relative;
    background-color: var(--color-hh-el-tertiary);
}

.footer svg {  
    color: var(--color-hh-el-tertiary);
}

.wrapper {
    width: 75%;
    max-width: 1200px;   
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /*            1fr 1fr 1fr; */
    grid-gap: 80px;
    border-bottom: 2px solid var(--color-t-turquoise);
    margin: 0 auto;
    padding-bottom: 30px;
    color: var(--color-hh-dark);
}

.wrapper h4 {
    color: var(--color-t-turquoise);
}

.list {
    text-align: right;
}


/** social media links **/
/* .social p {
    font-size: 0.9rem;
    font-weight: 100;
    line-height: 1;

} */

.socialIcon {
    background-color: rgba(255, 255, 255, 1);
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
    border-style: none;
    font-weight: 400;
    height: 2rem;
    width: 2rem;
    align-content: center;
    border-radius: 9999px;
    margin-right: 0.5rem;
    margin-bottom: 0.75rem;
    margin-right: .3rem;
}

.socialIcon:hover {
    background-color: var(--color-md-yellow);
    cursor: pointer;
}
.socialIcon svg {
    display: flex;
    margin: auto;
    font-size: 1.2rem;
}

/*  */
.copyright {
    text-align: center;
    padding: 1em;
    color: var(--color-t-turquoise);
}

/*  */
@media (max-width: 960px) {
    .footer {
        margin: auto;
    }

    .wrapper {
        grid-template-columns: repeat(1, 1fr);       
        margin: 0 auto;
        text-align: center;
    }

    .list {
        margin-top: -50px;
        text-align: center;
    }

}

/** contact info **/
.contact_inner_wrapper {
    font-size: 14px;
    margin-bottom: 15px;
}

@media (min-width: 992px) {
    .contact_inner_wrapper .item {
      text-align: left;
    }
}

.contact_inner_wrapper .item a {
    color: #212529;
    font-weight: 700;
}

.contact_inner_wrapper .item span {
    color: var(--fsw-color-pink);
    margin-right: 6px;
    display: inline-block;
}

.diag_color {
    color: var(--color-hh-el-paragraph);
}