.wrapper {
  position: relative;
}

.events {
  margin-bottom: 60px;
  position: relative;
}

.dateInfo {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  display: flex;
  background: #495057;
  color: #fff;
  padding: 3px 10px;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.dateInfo .month {
  margin-right: 5px;
  text-transform: uppercase;
}

.thumb {
  position: relative;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center center;
  border-radius: 0.25rem;
}

.upcomingIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 10;
}

.upcomingIcon svg {
  height: inherit;
  width: inherit;
}

.data {
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  background: var(--color-md-yellow);
  border-radius: 0.25rem;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  color: var(--color-hh-el-paragraph);
}

.data .daysLeft {
  font-size: 16px;
  color: var(--color-t-turquoise);
}

.data a.eventTitle {
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif;
  color: #343a40;
}

.data .subtitle {
  font-size: 12px;
  margin-bottom: 10px;
}

.data .teachers {
  font-size: 14px;
  color: #343a40;
  font-weight: 700;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: -40px;
}

.button a {
  border-radius: 0.25rem;
  margin: 0 10px;
  padding: 5px 10px;
  border: 2px solid #00cec9;
  background: #fff;
  color: #00cec9;
  transition: color 0.2s, background 0.2s;
}

.button a:hover,
.button a:focus,
.button a:active {
  background: #00cec9;
  color: #fff;
}

.buttons a {
  border-radius: 0.25rem;
  margin: 0 10px;
  padding: 5px 10px;
  border: 2px solid var(--color-hh-red);
  background: var(--color-hh-ice);
  color: var(--color-hh-red);
  transition: color 0.2s, background 0.2s;
}

.buttons a:hover,
.buttons a:focus,
.buttons a:active {
  background: var(--color-hh-red);
  color: var(--color-hh-ice);
}

@media (min-width: 992px) {
  /* add line to the timeline */
  .wrapper:before {
    content: "";
    position: absolute;
    border-style: dotted;
    left: 3.4%;
    top: 0;
    bottom: 0;
    width: 4px;
    height: 100%;
    background: var(--fsw-color-grey);

    /* dotted from https://www.magicpattern.design/tools/css-backgrounds */
    opacity: 0.8;
    background-image: linear-gradient(
      15deg,
      var(--color-hh-ice) 50%,
      var(--color-hh-red) 50%     /* ************* */
    );
    background-size: 15px 15px;
  }
  
  .events {
    display: flex;
    position: relative;
    margin-bottom: 90px;
    align-items: center;
  }

  .dateInfo {
    position: relative;
    display: block;
    text-align: right;
    background: var(--color-hh-ice);
    border-style: solid;
    border-color: #e2725b;
    margin: auto;
    padding: 10px 0;
    color: var(--pink);
    top: auto;
    left: auto;
    -webkit-transform: translate(0);
    transform: translate(0);
    border-radius: 25%;
    text-align: center;
  }

  .dateInfo .month {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 32px;
    line-height: 32px;
    margin-right: 0;
  }

  .dateInfo .year {
    font-size: 22px;
  }

  .thumb {
    margin: 0 30px;
    width: 80%;
    height: 340px;
    flex-grow: 1;
  }

  .thumb:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    border-style: dotted;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--green);
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
    z-index: -1;
    border-radius: 0.25rem;
  }

  /* .thumb .thumb-teachers-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
  } */

  /* .thumb .thumb-teachers-wrapper .teacher-avatar {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 50%;
    border: 3px solid #6c757d;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.35);
  } */

  .data {
    box-shadow: none;
    padding: 20px 20px 40px 20px;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
    text-align: left;
    border-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    /* border: 2px rgba(236, 72, 154, 0.5); */
    /* border: 2px var(--color-hh-red); */
    border-style: solid solid solid none;
  }

  .data a.evenTitle {
    display: inline-block;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif;
    color: #343a40;
  }

  .data .subtitle {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .buttons {
    position: absolute;
    bottom: 0;
    left: 30px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    display: flex;
    margin-bottom: 0px;
  }
}
