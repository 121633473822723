



.wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding-left: 5rem;
    padding-right: 5rem;
    flex-grow: 1;
    max-width: 1200px;
    justify-content: center;
}

.card {
    box-shadow: rgb(255, 255, 255);
    width: 33.333333%;     
    padding-top: 1.25rem; 
    padding-bottom: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    min-width: 300px; 
}

.cardsm_div {
    /* text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full */
    color: rgba(255, 255, 255, 1);
    padding: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin-bottom: 1.25rem;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
    border-radius: 9999px;
}

.card_desc {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: rgba(82, 82, 91, 1);
}

.cont_wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 550px;
}




.wrapper_two {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.5rem;
    box-shadow: rgba(17, 12, 46, 0.25) 3px 6px 12px -5px;
    border-radius: 9999px;
    background-color: rgba(244, 244, 245, 1);
}


.bullet_points_item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.bullet_points_item div {
    display: flex;
    align-items: center;
}

.bullet_points_item span {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    display: inline-block;
    padding: 0.38rem;
    border-radius: 9999px;
    color: white;
    background-color: rgba(240,132,108, 1);
    margin-right: 0.75rem;
}


.card_spec {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    overflow-wrap: break-word;
    /* background-color: rgba(255, 255, 255, 1); */
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 3px 6px 12px -3px rgba(17, 12, 46, 0.45);
    max-width: 300px;
    align-items: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

.white {
    background-color: rgba(255, 255, 255, 1);
}

.card_spec p {
    margin: 0.6rem;
    /* text-align: center; */
}

.img {
    max-width: 100%;
    border-radius: 0.5rem;
    /* box-shadow: rgba(17, 12, 46, 0.45) 3px 6px 12px -3px; */
}

.circle {
    display:block;
    margin-left: auto;
    margin-right: auto;
    width: 3rem;
    height: 3rem;
    border-radius: 9999px;
    background-color: rgba(255, 255, 255, 1);
}

.active {
    background-color: var(--color-md-yellow);
}

.our_values_head {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 1.25rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));

}

.card_ourteam_img {
    border-radius: 9999px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.card_blockquote_p {
    font-weight: 300;
    margin-top: 0.5rem;
    color: rgba(255, 255, 255, 1);
}

.card_ourvalue {
    min-width: 23%;
    max-width: 12.rem;
    padding-right: 1.25rem;
    text-align: center;
}

.card_blockquote_svg {
    position: absolute;
    left: 0px;
    width: 100%;
    display: block;
    height: "95px";
    top: "-94px"; 
}

.card_blockquote_svg_polygon {
    /* color: rgba(219, 39, 119, 1); */
    fill: currentColor;
}

.team_member_title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
}


.card_withnote {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    max-width: 462.5px;
    /* min-height: 578px; */
    margin-bottom: 1.5rem;
    box-shadow: rgba(17, 12, 46, 0.45) 3px 6px 12px -3px;
    border-radius: 0.5rem;
    background-color: rgba(240,132,108, 1);
    margin-left: 1rem;
    margin-right: 1rem;
}

.card_withnote blockquote {
    position: relative;
    padding: 2rem;
    margin-bottom: 1rem
}

.card_withnote blockquote h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);  
}


@media only screen and (min-width: 1028px) {
    .card_ourvalue {
        max-width: 33.33%;
    }
  
}