




.wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }

/*  */
.cover_img {
    position: relative;
    margin: 0;
    white-space: nowrap;
    margin: auto;
    font-size: 14px;
    margin-bottom: 80px;
}

.cover_img img {
    border-radius: 6px;
}

.cover_img .title_card {
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    padding: 30px;
    background: #fff;
    z-index: 10;
}

.cover_img .title_card:before {
    border-radius: 6px;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #fff;
}

.cover_img .title_card:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: calc(100% - 40px);
    z-index: -10;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.35);
    border-radius: 6px;
}

.img_fluid {
    max-width: 100%;
    height: auto;
    margin: auto;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: center;
}

.col12, .col_lg_8 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
}



@media (min-width: 992px) {
    .col_lg_8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    }
}



.title_card {
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    padding: 30px;
    background: #fff;
    z-index: 10;
}

.title_card:before {
    border-radius: 6px;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #fff;
}

.title_card:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: calc(100% - 40px);
    z-index: -10;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.35);
    border-radius: 6px;
}

.title_card {
  margin: 0;
  white-space: nowrap;
  font-size: 14px;
  margin: auto;
}

/*  */
h1.partner_title {
    white-space: nowrap;
}

.partner_title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

/*  */
.partner_content {
    text-align: justify;
}

