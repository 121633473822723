

.economy_wrapper {
    width: 100%;
    text-align: center;
}

.ul_economy {
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.ul_economy li {
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
}

/* ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
} */

.decoy_wrapper {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    /* flex-flow: wrap; */
    max-width: 1200px;
    margin: auto;
    padding-top: -16rem;
    padding-bottom: 6rem;
    top: 5rem;
}