
.nav {
    top: 0px;
    position: fixed;
    width: 100%;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 0.5rem;
    text-align: center;
    border-width: 0;
}

.nav_wrapper {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: center;
    max-width: 1200px;
}

.nav_main {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

/* Hamburger -- collapsible toggle */
.nav_toggle {
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.25rem 0.75rem;
    /* border-width: 1px; */
    /* border-style: solid; */
    border-color: transparent;
    border-radius: 0.25rem;
    background-color: transparent;
    /* outline: 2px solid transparent; */
    outline-offset: 2px;
    /* -webkit-appearance: button; */
    /* color: inherit; */
    /* background-image: none; */
    /* text-transform: none; */
    /* margin: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: inherit; */
}

.nav_toggle:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}


/* Logo -- link to home page */
.home_link {
    display: block;
    font-weight: 700;
    cursor: pointer;
    padding: 0.75rem;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 1.25rem;   
    border-style: solid;
    border-color: transparent;

}


.nav_menu li {
    display: block;
    text-align: center; 
}

.is_deactivated {
    display: none;
}


.li_item {
    padding: 0.5rem; 
}


.li_item .active {
    color: var(--color-hh-yellow);
}

.li_item_a {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: rgba(228, 228, 231, 1);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
}

.li_item_a:hover {
    color: rgb(228, 119, 173);
}

/*
 * Call to action button 
 */
 .btn {
    background-color: rgba(219, 39, 78, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.75rem;
    border-radius: 0.25rem;
    outline-offset: 2px;
    margin: 0.75rem;

    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow)

}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
    background-color: #E2725B;
}



@media (min-width: 1024px) {
    .nav {
        padding: 0rem;
        justify-content: center;

    }
    
    .nav_wrapper {
        margin: auto;
        text-align: center;
        display: flex;
    }

    .nav-div-div {
        width: auto;
        float: left;
        width: 10%;
        align-items: center;
    }

    .nav_toggle {
        display: none;
    }

    .nav_main {
        width: auto;
        float: left;
        width: 10%;
        align-items: center;
    }

    .nav_menu {
        display: flex;
        background-color: transparent;
        box-shadow: 0 0 #0000;
        align-items: center;
        
    }

    .nav_menu li {
        display: inline-block;
        /* text-align: center;  */
    }

    .center_wrapper {
        margin-right: auto;
        margin-left: auto;
    }

    .center_wrapper .list .li_item {
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .right_wrapper {
        display: inline-flex;
        align-items: center;
        margin-left: 62px;
    }

    .li_item {
        display: inline-block;
        padding: 0 1rem;
    }

    .li_item a {
        white-space: nowrap;
        display: block;
        height: 55px;
        display: flex;
        align-items: center;
        padding: 0 1px;
        font-family: "Barlow Condensed",sans-serif;
        font-weight: 700;
        /* font-size: 28px; */
        color: #FFF;
        text-shadow: 0px 0px 4px rgba(40,40,40,0.2);
        position: relative;
        text-transform: uppercase;
    }

    .sl_span {
        display: none;
    }

    .li_item_a {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    
        font-size: 1.025rem;
        line-height: 0.75rem;
        color: rgba(228, 228, 231, 1);
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    
        text-transform: uppercase;
        font-weight: 700;
    }

}